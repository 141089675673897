const getPrefixedEvents = (prefix: EventType, events: Record<string, string>) =>
  Object.fromEntries(
    Object.entries(events).map(([key, value]) => [key, `${prefix}: ${value}`]),
  );

enum EventType {
  MFV = "MFV",
}

export const MFV_EVENTS = getPrefixedEvents(EventType.MFV, {
  SIGNUP_ATTEMPTED: "Signup Attempted",
  CLICKED_SEND_CODE: "Clicked Send Code",
  CLICKED_CALL_INSTEAD: "Clicked Call Instead",
  CLICKED_VERIFY_CODE: "Clicked Verify Code",
  PHONE_ALREADY_USED_ERROR: "Received Phone Already Used Error",
  HCAPTCHA_COMPLETED: "hCaptcha Completed",
  EMAIL_VERIFICATION_SCREEN_SHOWN: "Email Verification Screen Shown",
});
